.feature-section {
    padding: 50px;
    height: auto;
    background: #F6F7FA;
}


.feature-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 31px;
    color: #000000;

}

.feature-text {
    display: flex;
}

.btn {
    margin-top: 30px;
    padding: 10px 15px;
    background: #0085FF;
    border-radius: 8px;
    border: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #FFFFFF;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .btn{
        float: inherit;
        margin-left: 50%;
        transform: translate(-50%,0%);
    }
    .feature-section {
        padding: 50px 0px;
    }

    .feature-text {
        text-align: center;
    }

    .feature-title {
        text-align: center;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}
