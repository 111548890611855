/*section 1*/
.thangchamay form{
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    width: 264px;
}

.uppercase {
    text-transform: uppercase;
}

.form__field {
    display: inline;
    width: 200px;
    background: #fff;
    color: #a3a3a3;
    border-radius: 10px!important;
    outline: 0;
    border: 1px ;
    padding: 5px 18px;
    /*opacity: .5;*/
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.thangchamay .btn{
    position: absolute;
    margin: 0!important;
    height: 35px;
    padding: 8px 15px!important;
    background-color: rgb(78, 67, 189);
    /*border: 1px solid #4e43bd;*/
    background: rgb(78,67,189);
    background: linear-gradient(270deg, rgba(78,67,189,0.8) 0%, rgba(78,67,189,1) 100%);
    font-weight: 500;
    line-height: 1;
    font-size: 16px!important;
    border-radius: 10px !important;
    margin-left: 10px!important;
}
.bg-slide {
    background-color: #e6e6e6;
    width: 100vw;
    height: 60vh;
    background: url("../../img/gallery/untitled.png") no-repeat;
    background-size: cover;
    background-position: right 10% bottom 25%;
}
.hero-text {
    position: absolute;
    z-index: 99;
    top: 20%;
    left: 15% !important;
}
.hero-text span{
    opacity: .8;
    color: #606060;
    font-weight: 500;
    font-size: 13px!important;
}
.hero-text h1 {
    line-height: 1.3;
    font-size: 50px;
}
.hero-text h5 {
    font-weight: 600;
    max-width: 400px;
    font-size: 16px;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .supper-center{
        display: block !important;
    }

    .bg-slide {
        width: 100vw;
        height: 50vh;
        background-position: right 10% bottom 25%;
    }
    .hero-text {
        position: unset;
        padding-bottom: 40px;
        background: white;
        top: 0;
        left: 0!important;
    }
    .hero-title{
        /*padding-top: 70px;*/
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .bg-slide {
        width: 100vw;
        height: 50vh;
        background-position: right 20% bottom 25%;
    }
    .hero-text {
        position: unset;
        padding-bottom: 40px;
        background: white;
        top: 0;
        left: 0!important;
    }
    .hero-title{
    }
}
/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .bg-slide {
        width: 100vw;
        height: 50vh;
        background-position: right 20% bottom 25%;
    }
    .hero-text {
        position: unset;
        padding-bottom: 40px;
        background: white;
        top: 0;
        left: 0!important;
    }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {

    .hero-text{
        left: 10% !important;
        transform: scale(0.9);
    }
}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {
    .hero-text {
        transform: scale(1.2);
        left: 15%;
    }
}