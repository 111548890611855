@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

body {
    background-color: white;
}

h1 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 43px;
    color: #000000;
}

h2 {

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* identical to box height */

    text-align: center;

    color: #000000;

}

h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 31px;
    /* identical to box height */

    color: #000000;
}

h5 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #000000;
}

h5 strong {
    font-size: 19px;
    font-weight: 600;
}

h5 span {
    font-weight: 800;
}

h6 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #000000;
}

span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: #000000;
}

.navbar-brand {
    margin-left: 20px !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    /* identical to box height */
    color: #4E43BD !important;
}


.m-top {
    margin-top: 100px;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .m-top {
        margin-top: 80px;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .m-top {
        margin-top: 80px;
    }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .m-top {
        margin-top: 60px;
    }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
    .m-top {
        margin-top: 80px;
    }

}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {

}

@media (min-width: 1501px) {

}


.download-button {

    display: inline;
}

.download-button img {
    height: 60px;
}

/**/
nav > button {
    margin-right: 30px;
}

footer ul {
    padding: 0;
}

footer li {
    padding: 0;
    list-style: none;
}

.contact {
    border-top: 2px solid #ffffff;
    padding-top: 40px;
    /*height: 50px;*/
}

a {
    text-decoration: none !important;
}

footer .nav-links {
    font-weight: 400;
    padding: 10px 0px;
    color: gray;
}

footer .nav-links:hover {
    padding: 0;
    color: #4e43bd;
}

.contact-text {
    font-size: 16px;
    font-weight: 300;
    padding: 0px 10px;
}

.contact-text a {
    padding-right: 10px;
    color: #373737;
}

.footer-links {
    font-size: 16px;
    color: gray;
    font-weight: 400;
    text-decoration: none;
    /*padding: 55rem 1rem;*/
    cursor: pointer;
    line-height: 1.8;

}

/* small mobile :320px. */
@media (max-width: 767px) {
    .super-center {
        display: block !important;
    }

    .csdk-pd {
        margin-top: 120px;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .super-center {
        display: block !important;
    }

    .csdk-pd {
        margin-top: 120px;
    }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .supper-center {
        display: block !important;
    }

    .csdk-pd {
        margin-top: 120px;
    }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
    .csdk-pd {
        margin-top: 120px;
        padding: 0 120px;
    }
}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {

    .csdk-pd {
        margin-top: 120px;
        padding: 0 120px;
    }
}

@media (min-width: 1501px) {
    .csdk-pd {
        margin-top: 120px;
        padding: 0 120px;
    }
}

footer .navbar-brand {
    margin-left: 0px !important;
}

footer .nav-links {
    font-size: 16px;
    color: gray;
    font-weight: 400;
    text-decoration: none;
    /* padding: 55rem 1rem; */
    cursor: pointer;
    line-height: 1.8;
}

form > * {
    display: flex;
    padding: 5px;
}

form > input {
    border-radius: 10px;
    border: 0.25px solid #ced4da;
    padding: 10px;
    width: 100%;
}

.mess-form {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 0.5px solid #ced4da;
    padding: 10px;
}

.submit-bg {
    background: url("./img/gallery/Group 3.jpg") no-repeat;
    background-size: cover;
}

/* small mobile :320px. */
@media (max-width: 767px) {

    .reason-img {
        width: 100%;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}

.line-wrap {
    margin-top: 50px;
    height: 3px;
    width: 100%;
    background-color: lavender;
    border-radius: 20px;
}

.help-bg {
    padding: 0px !important;
    width: 100%;
    height: 600px;
    background: url("img/gallery/aa.png");
    background-position: center center;
}

.block-content {
    padding: 5px;
    margin-right: 20px;
    text-align: left;
    font-weight: 600;
    border-radius: 10px;
}

.block-content:hover {
    background-color: #efefef;
}

.content-line {
    padding: 10px 0;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.content-line::after {
    border-radius: 20px;
    content: '';
    position: absolute;
    width: 3px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 0;
    bottom: 0;
    right: 0;
    margin-left: 33px;
}

.block-content::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    right: -6px;
    background-color: white;
    border: 4px solid #4e43bd;
    border-radius: 50%;
    z-index: 1;
}

.menu-position {
    position: fixed;
    top: 10%;
    right: 10px;
}

.menu-icon a {
    display: block;
    margin-top: 20px;
}

.is-hidden {
    display: none;
}

.content-details img {
    width: 40%;
    /*height: 100%;*/
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .content-details img {
        width: 100%;
    }

    .menu-position, .time-line {
        display: none;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}

.news .card-title {
    color: black;
    font-size: 20px;
    margin: 0;
}

.news img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.news .card-body {
    padding-top: 0;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.news .card {
    border-radius: 10px;
}

.btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;
}

.btn.green {
    border: 4px solid #4e43bd;
    color: #4e43bd;
    background-color: transparent;
}

.btn.green:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #4e43bd;
    z-index: -1;
    transition: 0.2s ease;
}

.btn.green:hover {
    color: var(--white);
    background: #4e43bd;
    transition: 0.2s ease;
}

.btn.green:hover:before {
    width: 100%;
}

@media screen and (max-width: 768px) {
    body {
        display: block;
    }

    .container {
        margin-top: 70px;
        margin-bottom: 70px;
    }
}