.btn-outline{
    width: 134px;
    height: 52px;
    padding: 10px 30px;
    background-color: transparent;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 36px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
}
.card{
    margin-top: 20px;

}
.card-01{
    float:right;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #4E43BD;
    border-radius: 19px;
}
.card-02{
    float:left;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #0085FF;
    border-radius: 19px;
}
.card-03{
    float:right;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #DC4F31;
    border-radius: 19px;
}
.card-04{
    float:left;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #96D836;
    border-radius: 19px;
}
.card-title{

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
}
/* small mobile :320px. */
@media (max-width: 767px) {
    .card-01, .card-02, .card-03, .card-04{
        float: inherit;
        margin: 0 auto;
        width: 99%;
        height: auto;
        margin-top: 20px;

    }
    .card-title{
        font-size: 21px;
    }
    .btn-outline{
        padding: 5px 15px;
        font-size: 17px;
    }
    .px-5{
        padding: 20px!important;
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}
@media (min-width: 1501px) {

}