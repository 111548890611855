@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background-color: white;
}

h1 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 43px;
    color: #000000;
}

h2 {

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    /* identical to box height */

    text-align: center;

    color: #000000;

}

h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 31px;
    /* identical to box height */

    color: #000000;
}

h5 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #000000;
}

h5 strong {
    font-size: 19px;
    font-weight: 600;
}

h5 span {
    font-weight: 800;
}

h6 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #000000;
}

span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: #000000;
}

.navbar-brand {
    margin-left: 20px !important;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    /* identical to box height */
    color: #4E43BD !important;
}


.m-top {
    margin-top: 100px;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .m-top {
        margin-top: 80px;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .m-top {
        margin-top: 80px;
    }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .m-top {
        margin-top: 60px;
    }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
    .m-top {
        margin-top: 80px;
    }

}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {

}

@media (min-width: 1501px) {

}


.download-button {

    display: inline;
}

.download-button img {
    height: 60px;
}

/**/
nav > button {
    margin-right: 30px;
}

footer ul {
    padding: 0;
}

footer li {
    padding: 0;
    list-style: none;
}

.contact {
    border-top: 2px solid #ffffff;
    padding-top: 40px;
    /*height: 50px;*/
}

a {
    text-decoration: none !important;
}

footer .nav-links {
    font-weight: 400;
    padding: 10px 0px;
    color: gray;
}

footer .nav-links:hover {
    padding: 0;
    color: #4e43bd;
}

.contact-text {
    font-size: 16px;
    font-weight: 300;
    padding: 0px 10px;
}

.contact-text a {
    padding-right: 10px;
    color: #373737;
}

.footer-links {
    font-size: 16px;
    color: gray;
    font-weight: 400;
    text-decoration: none;
    /*padding: 55rem 1rem;*/
    cursor: pointer;
    line-height: 1.8;

}

/* small mobile :320px. */
@media (max-width: 767px) {
    .super-center {
        display: block !important;
    }

    .csdk-pd {
        margin-top: 120px;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .super-center {
        display: block !important;
    }

    .csdk-pd {
        margin-top: 120px;
    }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .supper-center {
        display: block !important;
    }

    .csdk-pd {
        margin-top: 120px;
    }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
    .csdk-pd {
        margin-top: 120px;
        padding: 0 120px;
    }
}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {

    .csdk-pd {
        margin-top: 120px;
        padding: 0 120px;
    }
}

@media (min-width: 1501px) {
    .csdk-pd {
        margin-top: 120px;
        padding: 0 120px;
    }
}

footer .navbar-brand {
    margin-left: 0px !important;
}

footer .nav-links {
    font-size: 16px;
    color: gray;
    font-weight: 400;
    text-decoration: none;
    /* padding: 55rem 1rem; */
    cursor: pointer;
    line-height: 1.8;
}

form > * {
    display: flex;
    padding: 5px;
}

form > input {
    border-radius: 10px;
    border: 0.25px solid #ced4da;
    padding: 10px;
    width: 100%;
}

.mess-form {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 0.5px solid #ced4da;
    padding: 10px;
}

.submit-bg {
    background: url("/static/media/Group 3.642b7379.jpg") no-repeat;
    background-size: cover;
}

/* small mobile :320px. */
@media (max-width: 767px) {

    .reason-img {
        width: 100%;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}

.line-wrap {
    margin-top: 50px;
    height: 3px;
    width: 100%;
    background-color: lavender;
    border-radius: 20px;
}

.help-bg {
    padding: 0px !important;
    width: 100%;
    height: 600px;
    background: url(/static/media/aa.20d2df7c.png);
    background-position: center center;
}

.block-content {
    padding: 5px;
    margin-right: 20px;
    text-align: left;
    font-weight: 600;
    border-radius: 10px;
}

.block-content:hover {
    background-color: #efefef;
}

.content-line {
    padding: 10px 0;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.content-line::after {
    border-radius: 20px;
    content: '';
    position: absolute;
    width: 3px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 0;
    bottom: 0;
    right: 0;
    margin-left: 33px;
}

.block-content::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    right: -6px;
    background-color: white;
    border: 4px solid #4e43bd;
    border-radius: 50%;
    z-index: 1;
}

.menu-position {
    position: fixed;
    top: 10%;
    right: 10px;
}

.menu-icon a {
    display: block;
    margin-top: 20px;
}

.is-hidden {
    display: none;
}

.content-details img {
    width: 40%;
    /*height: 100%;*/
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .content-details img {
        width: 100%;
    }

    .menu-position, .time-line {
        display: none;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}

.news .card-title {
    color: black;
    font-size: 20px;
    margin: 0;
}

.news img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.news .card-body {
    padding-top: 0;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.news .card {
    border-radius: 10px;
}

.btn {
    z-index: 1;
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 8px 50px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1em;
    letter-spacing: 2px;
    transition: 0.2s ease;
    font-weight: bold;
    margin: 5px 0px;
}

.btn.green {
    border: 4px solid #4e43bd;
    color: #4e43bd;
    background-color: transparent;
}

.btn.green:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background: #4e43bd;
    z-index: -1;
    transition: 0.2s ease;
}

.btn.green:hover {
    color: var(--white);
    background: #4e43bd;
    transition: 0.2s ease;
}

.btn.green:hover:before {
    width: 100%;
}

@media screen and (max-width: 768px) {
    body {
        display: block;
    }

    .container {
        margin-top: 70px;
        margin-bottom: 70px;
    }
}
.super-center {
    display: grid;
    place-items: center;
}

.one-height {
    height: 100vh !important;
}

.bg-trans{
    background: transparent !important;
}
.NavBarItems {
    position: fixed;
    height: auto;
    /*width: vw;*/
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

nav button{
    border-color: transparent;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 85vw;
    justify-content: end;
    margin-right: 2rem;
}
.nav-menu .btn{
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}
.nav-links {
    font-size: 16px;
    color: black;
    font-weight: 600;
    text-decoration: none;
    padding: 0.5rem 1rem .2rem 1rem;
    cursor: pointer;
    line-height: 1.8;
}

.nav-links:hover {
    color: #4e43bd;
}

.fa-bars, .fa-times {
    color: #4e43bd;
    font-size: 30px;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .nav-menu {
        display: block;
        width: 80vw;

    }
    .nav-links {
        font-size: 24px;
        text-align: center;
        color: black;
        font-weight: 600;
        text-decoration: none;
        padding: 0.5rem 1rem;
        cursor: pointer;
        line-height: 3;
    }

}

/* small mobile :320px. */
@media (max-width: 767px) {
    .bg-trans{
        background: white !important;
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .bg-trans{
        background: white !important;
    }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}

/*section 1*/
.thangchamay form{
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    width: 264px;
}

.uppercase {
    text-transform: uppercase;
}

.form__field {
    display: inline;
    width: 200px;
    background: #fff;
    color: #a3a3a3;
    border-radius: 10px!important;
    outline: 0;
    border: 1px ;
    padding: 5px 18px;
    /*opacity: .5;*/
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.thangchamay .btn{
    position: absolute;
    margin: 0!important;
    height: 35px;
    padding: 8px 15px!important;
    background-color: rgb(78, 67, 189);
    /*border: 1px solid #4e43bd;*/
    background: rgb(78,67,189);
    background: linear-gradient(270deg, rgba(78,67,189,0.8) 0%, rgba(78,67,189,1) 100%);
    font-weight: 500;
    line-height: 1;
    font-size: 16px!important;
    border-radius: 10px !important;
    margin-left: 10px!important;
}
.bg-slide {
    background-color: #e6e6e6;
    width: 100vw;
    height: 60vh;
    background: url(/static/media/untitled.83a4f478.png) no-repeat;
    background-size: cover;
    background-position: right 10% bottom 25%;
}
.hero-text {
    position: absolute;
    z-index: 99;
    top: 20%;
    left: 15% !important;
}
.hero-text span{
    opacity: .8;
    color: #606060;
    font-weight: 500;
    font-size: 13px!important;
}
.hero-text h1 {
    line-height: 1.3;
    font-size: 50px;
}
.hero-text h5 {
    font-weight: 600;
    max-width: 400px;
    font-size: 16px;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .supper-center{
        display: block !important;
    }

    .bg-slide {
        width: 100vw;
        height: 50vh;
        background-position: right 10% bottom 25%;
    }
    .hero-text {
        position: unset;
        padding-bottom: 40px;
        background: white;
        top: 0;
        left: 0!important;
    }
    .hero-title{
        /*padding-top: 70px;*/
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .bg-slide {
        width: 100vw;
        height: 50vh;
        background-position: right 20% bottom 25%;
    }
    .hero-text {
        position: unset;
        padding-bottom: 40px;
        background: white;
        top: 0;
        left: 0!important;
    }
    .hero-title{
    }
}
/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
    .bg-slide {
        width: 100vw;
        height: 50vh;
        background-position: right 20% bottom 25%;
    }
    .hero-text {
        position: unset;
        padding-bottom: 40px;
        background: white;
        top: 0;
        left: 0!important;
    }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {

    .hero-text{
        left: 10% !important;
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {
    .hero-text {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        left: 15%;
    }
}
.reason-card {
    margin: 0 5px;
    width: 400px;
    padding: 30px 30px;
    background: #F4F7FA;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: 0;
}

.ads {
    /*width: 880px;*/
    height: 280px;
    padding: 50px;
    background: linear-gradient(90deg, #4E43BD 0%, #0085FF 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 36px;
}
.ads h3{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
}
.ads h6{
    max-width: 386px;
    height: 110px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #FFFFFF;

    opacity: 0.8;
}
.ads-img {
    position: absolute;
    width: 347px;
    height: 325.13px;
    left: 682px;
    top: 953px;
    z-index: 12;
}
/* small mobile :320px. */
@media (max-width: 767px) {
    .reason-card {
        margin: 10px;
        /*width: 100%;*/
        border-radius: 0;
    }
    .ads {
        width: 100vw;
        height: auto;
        padding: 50px;
        background: url("/static/media/Group 759.180da6c9.png"), linear-gradient(90deg, #4E43BD 0%, #0085FF 100%);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        margin: 0;
    }
    .ads-img {
        position: center;
        left: 0px;
        top: 20%;
    }
    .window-card{
        width: 100%!important;
        height: auto !important;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}
.window-card{
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    width: 70%;
    height: 600px;
    border-radius: 20px;
}
.title-bar{
    float: bottom;
}
.win-img{
    background: url(/static/media/qq2.872b553c.png);
    margin: 15px 0;
    border-radius: 20px ;
    width: 100%;
    height: 570px;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
}
.reason-container h3{
text-align: center;
    margin: 5px ;
    font-weight: 700;
}
.reason-container h4{
    text-align: center;
    margin: 5px ;
}
.increment{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 31px;
    /* identical to box height */

    color: #000000;
}
.feature-section {
    padding: 50px;
    height: auto;
    background: #F6F7FA;
}


.feature-title {
    display: flex;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 31px;
    color: #000000;

}

.feature-text {
    display: flex;
}

.btn {
    margin-top: 30px;
    padding: 10px 15px;
    background: #0085FF;
    border-radius: 8px;
    border: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #FFFFFF;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .btn{
        float: inherit;
        margin-left: 50%;
        -webkit-transform: translate(-50%,0%);
                transform: translate(-50%,0%);
    }
    .feature-section {
        padding: 50px 0px;
    }

    .feature-text {
        text-align: center;
    }

    .feature-title {
        text-align: center;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}

video {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}
.gradient-video:before{
    content:"";
    position: absolute;
    z-index:1;
    background:linear-gradient(to right,rgba(65, 0, 255, 0.4),rgba(255, 0, 232, 0.3));
}

#home-top-video:before {
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:1;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
}

#home-top-video {
    height: 50vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: -1;
}

#home-text {

    padding: 50px;
    /*background-color: white;*/
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index:2;
}
#home-text h3{
    font-size: 3rem;
    color: white;
}
.btn-outline{
    width: 134px;
    height: 52px;
    padding: 10px 30px;
    background-color: transparent;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 36px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
}
.card{
    margin-top: 20px;

}
.card-01{
    float:right;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #4E43BD;
    border-radius: 19px;
}
.card-02{
    float:left;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #0085FF;
    border-radius: 19px;
}
.card-03{
    float:right;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #DC4F31;
    border-radius: 19px;
}
.card-04{
    float:left;
    width: 80%;
    height: 267px;
    padding: 40px;
    background: #96D836;
    border-radius: 19px;
}
.card-title{

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
}
/* small mobile :320px. */
@media (max-width: 767px) {
    .card-01, .card-02, .card-03, .card-04{
        float: inherit;
        margin: 0 auto;
        width: 99%;
        height: auto;
        margin-top: 20px;

    }
    .card-title{
        font-size: 21px;
    }
    .btn-outline{
        padding: 5px 15px;
        font-size: 17px;
    }
    .px-5{
        padding: 20px!important;
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}
@media (min-width: 1501px) {

}
.premium-area {
    background-color: #f7f7ff;
    padding: 50px;
    padding-bottom: 150px;
}
.premium-area h4{
    text-align: center;
}
.price-card {
    border: 0;
    /*background-color: #e6e6e6;*/
    border-radius: 15px;
    transition: all .5s;
}
.price-card:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.card-head{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    line-height: 15px;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 25px;
    text-align: center;
    color: #757575;
}
.price-title{
    /*padding: 30px;*/
    font-weight: 700;
    font-size: 60px !important;
    text-align: center;
    color: #4e43bd;
}
.price-card span{
    font-size: 20px;
    font-weight: 600;
}
.title{
    display: block;
    padding: 0px 20px 30px 20px;
    font-size: 20px;
    color: black;
}
.feature i{
    margin-right: 10px;
}
.title .feature {
    margin: 0px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    color: #858585;
    text-align: center;

}
.price-card .btn{
    border-radius: 50px;
    font-size: 16px;
    width: 250px;
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 15px 20px;
}
/* small mobile :320px. */
@media (max-width: 767px) {
    .price-card .btn{
        border-radius: 50px;
        background-color: #4e43bd;
        font-size: 16px;
        width: auto;
        margin-top: 0px;
        margin-bottom: 30px;
        padding: 15px 20px;
    }
    .premium-area{
        padding: 0px;
        padding-bottom: 50px;
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}
@media (min-width: 1501px) {

}
.plan-details > * {
    text-align: center;
    cursor:help;
}
.plan-details span{
    font-size: 20px;
    font-weight: 600;
}
.plan-details .row{
    padding: 1rem;
}
.plan-details h5{
    font-weight: 600;
}
.fa-minus{
    opacity: .2;
}
.fa-check-circle-o{
    font-size: 20px;
    color: #4e43bd;
}

.icon-box {
    position: relative;
    display: block;
    padding: 40px 20px 30px;
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    z-index: 99;
    text-decoration: none
}

.col-md-4 {
    margin-top: 10px
}

.icon-box .icon {
    margin-bottom: 24px
}

.icon-box:hover {
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    /*transform:translateY(-15px);*/
}

.icon-box .content .title.fz-20 {
    font-size: 20px
}

.icon-box .content .title {
    font-size: 24px;
    line-height: 1.5;
    margin: 0;
    color: #3f3a64
}

.icon-box .content .desc {
    margin-top: 10px
}

.icon-box .content .desc p {
    line-height: 1.6;
    color: #696969
}

p:last-child {
    margin-bottom: 0
}

.icon-box:hover .content .link {
    color: #4e43bd;
}

.icon-box .content .link {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    margin-top: 34px;
    padding: 5px 0;
    color: #8c89a2
}

.icon-box .content .link i {
    font-size: 16px;
    line-height: 14px;
    margin-left: 14px
}
.news .btn {
    margin-left: 0;
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
}
