video {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}
.gradient-video:before{
    content:"";
    position: absolute;
    z-index:1;
    background:linear-gradient(to right,rgba(65, 0, 255, 0.4),rgba(255, 0, 232, 0.3));
}

#home-top-video:before {
    content:"";
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:1;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
}

#home-top-video {
    height: 50vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: -1;
}

#home-text {

    padding: 50px;
    /*background-color: white;*/
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index:2;
}
#home-text h3{
    font-size: 3rem;
    color: white;
}