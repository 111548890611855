.bg-trans{
    background: transparent !important;
}
.NavBarItems {
    position: fixed;
    height: auto;
    /*width: vw;*/
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

nav button{
    border-color: transparent;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 85vw;
    justify-content: end;
    margin-right: 2rem;
}
.nav-menu .btn{
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}
.nav-links {
    font-size: 16px;
    color: black;
    font-weight: 600;
    text-decoration: none;
    padding: 0.5rem 1rem .2rem 1rem;
    cursor: pointer;
    line-height: 1.8;
}

.nav-links:hover {
    color: #4e43bd;
}

.fa-bars, .fa-times {
    color: #4e43bd;
    font-size: 30px;
}

/* small mobile :320px. */
@media (max-width: 767px) {
    .nav-menu {
        display: block;
        width: 80vw;

    }
    .nav-links {
        font-size: 24px;
        text-align: center;
        color: black;
        font-weight: 600;
        text-decoration: none;
        padding: 0.5rem 1rem;
        cursor: pointer;
        line-height: 3;
    }

}

/* small mobile :320px. */
@media (max-width: 767px) {
    .bg-trans{
        background: white !important;
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    .bg-trans{
        background: white !important;
    }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}
