.premium-area {
    background-color: #f7f7ff;
    padding: 50px;
    padding-bottom: 150px;
}
.premium-area h4{
    text-align: center;
}
.price-card {
    border: 0;
    /*background-color: #e6e6e6;*/
    border-radius: 15px;
    transition: all .5s;
}
.price-card:hover{
    transform: scale(1.1);
}
.card-head{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    line-height: 15px;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 25px;
    text-align: center;
    color: #757575;
}
.price-title{
    /*padding: 30px;*/
    font-weight: 700;
    font-size: 60px !important;
    text-align: center;
    color: #4e43bd;
}
.price-card span{
    font-size: 20px;
    font-weight: 600;
}
.title{
    display: block;
    padding: 0px 20px 30px 20px;
    font-size: 20px;
    color: black;
}
.feature i{
    margin-right: 10px;
}
.title .feature {
    margin: 0px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    color: #858585;
    text-align: center;

}
.price-card .btn{
    border-radius: 50px;
    font-size: 16px;
    width: 250px;
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 15px 20px;
}
/* small mobile :320px. */
@media (max-width: 767px) {
    .price-card .btn{
        border-radius: 50px;
        background-color: #4e43bd;
        font-size: 16px;
        width: auto;
        margin-top: 0px;
        margin-bottom: 30px;
        padding: 15px 20px;
    }
    .premium-area{
        padding: 0px;
        padding-bottom: 50px;
    }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}
@media (min-width: 1501px) {

}
.plan-details > * {
    text-align: center;
    cursor:help;
}
.plan-details span{
    font-size: 20px;
    font-weight: 600;
}
.plan-details .row{
    padding: 1rem;
}
.plan-details h5{
    font-weight: 600;
}
.fa-minus{
    opacity: .2;
}
.fa-check-circle-o{
    font-size: 20px;
    color: #4e43bd;
}

.icon-box {
    position: relative;
    display: block;
    padding: 40px 20px 30px;
    -webkit-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    -o-transition: all .25s cubic-bezier(.645, .045, .355, 1);
    transition: all .25s cubic-bezier(.645, .045, .355, 1);
    border-radius: 5px;
    z-index: 99;
    text-decoration: none
}

.col-md-4 {
    margin-top: 10px
}

.icon-box .icon {
    margin-bottom: 24px
}

.icon-box:hover {
    z-index: 1;
    background-color: #fff;
    -webkit-box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    /*transform:translateY(-15px);*/
}

.icon-box .content .title.fz-20 {
    font-size: 20px
}

.icon-box .content .title {
    font-size: 24px;
    line-height: 1.5;
    margin: 0;
    color: #3f3a64
}

.icon-box .content .desc {
    margin-top: 10px
}

.icon-box .content .desc p {
    line-height: 1.6;
    color: #696969
}

p:last-child {
    margin-bottom: 0
}

.icon-box:hover .content .link {
    color: #4e43bd;
}

.icon-box .content .link {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 34px;
    padding: 5px 0;
    color: #8c89a2
}

.icon-box .content .link i {
    font-size: 16px;
    line-height: 14px;
    margin-left: 14px
}
.news .btn {
    margin-left: 0;
    transform: translate(0,0);
}