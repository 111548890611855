.reason-card {
    margin: 0 5px;
    width: 400px;
    padding: 30px 30px;
    background: #F4F7FA;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    border: 0;
}

.ads {
    /*width: 880px;*/
    height: 280px;
    padding: 50px;
    background: linear-gradient(90deg, #4E43BD 0%, #0085FF 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 36px;
}
.ads h3{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
}
.ads h6{
    max-width: 386px;
    height: 110px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #FFFFFF;

    opacity: 0.8;
}
.ads-img {
    position: absolute;
    width: 347px;
    height: 325.13px;
    left: 682px;
    top: 953px;
    z-index: 12;
}
/* small mobile :320px. */
@media (max-width: 767px) {
    .reason-card {
        margin: 10px;
        /*width: 100%;*/
        border-radius: 0;
    }
    .ads {
        width: 100vw;
        height: auto;
        padding: 50px;
        background: url("../../img/hero/Group 759.png"), linear-gradient(90deg, #4E43BD 0%, #0085FF 100%);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px;
        margin: 0;
    }
    .ads-img {
        position: center;
        left: 0px;
        top: 20%;
    }
    .window-card{
        width: 100%!important;
        height: auto !important;
    }
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {


}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {


}

@media (min-width: 1501px) {

}
.window-card{
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    width: 70%;
    height: 600px;
    border-radius: 20px;
}
.title-bar{
    float: bottom;
}
.win-img{
    background: url('/src/img/gallery/qq2.png');
    margin: 15px 0;
    border-radius: 20px ;
    width: 100%;
    height: 570px;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
}
.reason-container h3{
text-align: center;
    margin: 5px ;
    font-weight: 700;
}
.reason-container h4{
    text-align: center;
    margin: 5px ;
}
.increment{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 31px;
    /* identical to box height */

    color: #000000;
}